import {
  forwardRef,
  useRef,
  useContext,
  useMemo,
  useEffect,
} from 'react'
import classnames from 'classnames'
import { DataSource } from '@wiz/store'
import SelectedDataView from '@/context/SelectedDataView'
import SelectedDataSource from '@/context/SelectedDataSource'
import DataExplorer from '@/context/DataExplorer'
import DataExplorerExecute from '@/context/DataExplorerExecute'
import { OperateContext } from '@/pages/context/operate'
import DataSourceExplorer from '@/containers/Form/DataSourceExplorer'
import DataViewExplorer from '@/components/Form/DataViewExplorer'
import ConditionExplorer from '@/components/Form/ConditionExplorer'
// import EventSourceExplorer from '@/components/Form/EventSourceExplorer'
import useSplit from '@/hooks/useSplit'

const DefaultSplitSizes = [ 40, 40, 20, 0 ]

const LayoutSettings = forwardRef(({ className }, ref) => {
  const refDataSources = useRef()
  const refDataViews = useRef()
  const refConditions = useRef()
  const refEvents = useRef()
  const context = useContext(DataExplorer)
  const runContext = useContext(DataExplorerExecute)
  const { selectedSensorsContext, setSelectedSensorsContext } = useContext(OperateContext)

  const splitItems = useMemo(() => [
    refDataSources,
    refDataViews,
    refConditions,
    refEvents,
  ], [])

  useSplit({
    items: splitItems,
    sizes: context.settings.explorerViewSplitSettings,
    defaultSizes: DefaultSplitSizes,
    onChange: context.updateSplitSettings,
  })

  const selectedDataSources = useMemo(() => (
    context.data.dataSources
      .map(item => ({
        id: item.id,
        name: DataSource.getDisplayName(item, false),
        sensorId: item.sensorId,
        dataType: item.dataType,
        selected: true,
        params: item,
      }))
  ), [ context.data.dataSources ])

  useEffect(() => {
    if (selectedSensorsContext?.dataSources?.length) {
      context.setCurrentContext(selectedSensorsContext)
    }
  }, [])

  useEffect(() => {
    if (context.data.dataSources.length) {
      setSelectedSensorsContext(context.data)
    }
  }, [ context.createDataSource ])

  const selectedDataViews = useMemo(() => (
    context.data.dataViews
      .filter(item => (
        item.source
      ))
      .map(item => ({
        id: item.source.id,
        name: item.name || DataSource.getDisplayName(item.source, false),
        sensorId: item.source.sensorId,
        dataType: item.source.dataType,
        color: item.color,
        selected: true,
        params: item.source,
      }))
  ), [ context.data.dataViews ])

  const sensorIds = useMemo(() => (
    context.data.dataSources
      .filter(item => item.sensorId)
      .map(item => item.sensorId)
  ), [ context.data.dataSources ])

  return (
    <div
      ref={ref}
      className={classnames('d-flex flex-column position-relative min-h-0 min-w-0', className)}
    >
      <div
        ref={refDataSources}
        className="position-relative overflow-hidden d-flex flex-column bg-white"
      >
        <DataSourceExplorer
          value={sensorIds}
          batchOnly={context.data.dataFilter.stepType === 'batch'}
          onChange={context.createDataSource}
        />
      </div>

      <div
        ref={refDataViews}
        className="position-relative overflow-hidden d-flex flex-column bg-white"
      >
        <SelectedDataSource.Provider value={selectedDataSources}>
          <DataViewExplorer
            options={context.data.dataViews}
            errors={runContext.errors}
            onRemoveView={context.removeDataView}
            onChangeView={context.changeDataView}
            onChangeSource={context.changeDataSource}
            onCreateSource={context.createDataSource}
            onDrop={context.dropDataView}
          />
        </SelectedDataSource.Provider>
      </div>

      <div
        ref={refConditions}
        className="position-relative overflow-hidden d-flex flex-column bg-white"
      >
        <SelectedDataView.Provider value={selectedDataViews}>
          <ConditionExplorer
            options={context.data.conditions}
            applied={runContext.appliedConditions}
            onCreateCondition={context.createCondition}
            onRemoveCondition={context.removeCondition}
            onChangeCondition={context.changeCondition}
            onDrop={context.dropCondition}
          />
        </SelectedDataView.Provider>
      </div>

      <div
        ref={refEvents}
        className="position-relative overflow-hidden d-flex flex-column bg-white"
      >
        {/* <SelectedDataView.Provider value={selectedDataViews}>
          <EventSourceExplorer
            options={context.data.eventSources}
            applied={runContext.appliedEvents}
            onRemoveEventSource={context.removeEventSource}
            onReplaceEventSource={context.replaceEventSource}
          />
        </SelectedDataView.Provider> */}
      </div>
    </div>
  )
})

LayoutSettings.displayName = 'LayoutSettings'
export default LayoutSettings
